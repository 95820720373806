import React from 'react';

import Layout from '../../components/Layout';

export default function UndAktivDabeiSein() {
	return (
		<Layout title="Aktiv dabei sein">
			<h2>Mitmachen und aktiv dabei sein!</h2>
			<p>
				Jeden 1. und 3. Montag im Monat findet um 20 Uhr unser Bereitschaftsabend in der Riedelstr. 18 in Bad
				Reichenhall statt.
			</p>
			<p>
				Mit interessanten Vorträgen oder kurzen Ausbildungen zum Thema Medizin und Erste Hilfe und
				anschließendem &bdquo;gemütlichen zusammensitzen&ldquo; in unserem Stüberl laden wir Sie herzlich ein,
				einfach mal unverbindlich vorbei zu kommen!
			</p>
		</Layout>
	);
}
